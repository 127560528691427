@import "../../styles/defaults.scss";

.three_sixty_animation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 950px;
    max-height: inherit;
    width: 100%;
    height: 100%;

    // pinching wrapper for mobile only
    .react-transform-wrapper {
        margin-left: auto;
        margin-right: auto;
    }

    .three_d_images {
        user-select: none;
        opacity: 0;
        overflow: hidden;
        position: relative;
        max-width: 900px;
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .three_d_image {
            transform: initial;
            display: none;
            margin: auto;
            left: 0; // for zoom in/out
            right: 0; // for zoom in/out
            max-height:100%;
            max-width:100%;
            object-fit: contain;
        }

        .active_image {
            display: block;
        }

        &.show_td_images {
            opacity: 1;
        }

        &.tdi_full_width {
            max-width: 1000px;
            margin-right: auto;
        }
    }

    .zoom_buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        max-width: 900px;
        margin-bottom: 15px;

        .start_stop_button,
        .zoom_in_btn,
        .zoom_out_btn {
            cursor: pointer;

            svg .border {
                transition: fill 300ms;
                fill: $dp-mid-grey;
            }

            &:hover {
                svg .border {
                    fill: $dp-black;
                }
            }

            &.inactive {
                pointer-events: none;

                svg {
                    fill: $dp-mid-grey;
                }

                svg .border {
                    fill: $dp-mid-grey;
                }
            }
        }

        .start_stop_button,
        .zoom_out_btn {
            margin-right: 30px;
        }

        &.tdi_full_width {
            max-width: 1000px;
            margin-right: auto;
        }
    }
}

.three_sixty_thumbnails .three_sixty_animation {
    justify-content: center;
}

.three_sixty_view .three_sixty_animation .three_d_images .three_d_image {
    cursor: pointer;
}
