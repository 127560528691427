@import "../../styles/defaults.scss";

.showcase-container {
    width: 100%;
    height: 100%;
    background-color: $dp-white;

    &.no_background {
        background-color: transparent;
    }   

    .fall_back_img {
        display: none;
        height: 100%;
        width: 100%;
        object-fit: contain;
        margin: auto;

        &.fbi_show {
            display: block;
        }
    }
}

.three_sixty_view {
    display: flex;
    flex-direction: column;
    max-width: 550px;
    height: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    .three_sixty_container {
        width: 100%;

        .three_sixty_animation {
            max-width: initial;

            .three_d_images {
                margin-bottom: 10px;

                .three_d_image {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    .gallery_buttons {
        .close_g3d_overlay_btn {
            top: 25px;
            right: 25px;
            position: absolute;
            cursor: pointer;
            z-index: 1;
            
            svg {
                background: rgba(255, 255, 255, 0.75);
                border-radius: 28px;

                .border {
                    transition: fill 300ms;
                    fill: $dp-mid-grey;
                }
            }

            &:hover {
                svg .border {
                    fill: $dp-black;
                }
            }
        }
    }
}

.mobile_gallery_buttons .close_g3d_overlay_btn {
    position: absolute;
    right: 15px;
    cursor: pointer;
    z-index: 1;
    
    svg {
        background: rgba(255, 255, 255, 0.75);
        border-radius: 28px;

        .border {
            transition: fill 300ms;
            fill: $dp-mid-grey;
        }
    }

    &:hover {
        svg .border {
            fill: $dp-black;
        }
    }
}

@media only screen and (min-width: $breakpoint-desktop-min-width) {
    .three_sixty_view {
        flex-direction: row;
        max-width: inherit;
        justify-content: space-between;

        .gallery_buttons {
            margin-bottom: inherit;
        }

        .three_sixty_container .three_sixty_animation .three_d_images {
            margin-top: 85px;
        }
    }
}
