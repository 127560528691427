.loading_spinner {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: #000;

    &.show_loading_spinner {
        display: flex;
    }

    .scs_loader,
    .scs_loader:before,
    .scs_loader:after {
        border-radius: 50%;
    }
    .scs_loader {
        color: #ffffff;
        font-size: 4px;
        text-indent: -99999em;
        margin: 55px auto;
        position: relative;
        width: 26px;
        height: 26px;
        box-shadow: inset 0 0 0 2px;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }
    .scs_loader:before,
    .scs_loader:after {
        position: absolute;
        content: '';
    }
    .scs_loader:before {
        width: 5em;
        height: 10em;
        border-radius: 10em 0 0 10em;
        background: #000;
        top: -1.8em;
        left: -1.8em;
        -webkit-transform-origin: 5em 5em;
        transform-origin: 5em 5em;
        -webkit-animation: load2 2s infinite ease 1.5s;
        animation: load2 2s infinite ease 1.5s;
    }
    .scs_loader:after {
        width: 5em;
        height: 10em;
        border-radius: 0 10em 10em 0;
        background: #000;
        top: -1.8em;
        left: 3.2em;
        -webkit-transform-origin: 0.1em 5em;
        transform-origin: 0.1em 5.1em;
        -webkit-animation: load2 2s infinite ease;
        animation: load2 2s infinite ease;
    }

    @-webkit-keyframes load2 {
        0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        }
        100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        }
    }

    @keyframes load2 {
        0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        }
        100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        }
    }

}
