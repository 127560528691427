$breakpoint-mobile-extra-small: 320px;
$breakpoint-mobile-min-width: 769px;
$breakpoint-tablet-min-width: 1024px;
$breakpoint-desktop-min-width: 1200px;

$dp-red: #e30613;
$dp-eyeCatcher-new: #f0b400;
$dp-green: #70a848;
$dp-orange: orange;
$dp-black: #000000;
$dp-dark-gray: #1e1e1e;
$dp-white: white;
$dp-Gainsboro: #e6e6e6;
$dp-boxshadow: #d6d6f5;
$dp-dropwodn-boxshadow: rgba(0, 0, 0, 0.2);
$dp-light-grey: #d4d4d4;
$dp-grey: #d6d6d6;
$dp-dark-grey: #787878;
$dp-grey-inactive: #bbbbbb;
$dp-mid-grey: #d3d4d4;
$scs-grey-v2: #f0f1f1;

// exports js variables
:export {
    breakpointDesktopMinWidth: $breakpoint-desktop-min-width;
    dpWhite: $dp-white;
    dpGainsboro: $dp-Gainsboro;
}
