@import "../../styles/defaults.scss";

.three_sixty_thumbnails_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;

    .t_arrow_left,
    .t_arrow_right {
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
        width: 45px;
        height: 20px;
        transform: rotate(-90deg);
    }

    .three_sixty_thumbnails {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        margin-right: auto;
        margin-left: auto;
        max-width: 390px;
        
        .thumbnail_slot {
            transition: all 1s;
            display: flex;
            flex-direction: column;

            .t_slot_image_container {
                height: 100px;
                width: 100px;
                cursor: pointer;
                border: solid $dp-mid-grey 1px;
                margin-left: 15px;
                margin-right: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: border 300ms;

                &.selected {
                    border: solid $dp-black 1px;
                }

                &:hover {
                    border: solid $dp-black 1px;
                }

                .t_slot_image {
                    max-height: 80px;
                    max-width: 80px;
                }
            }

            .t_slot_label {
                color: $dp-black;
                font-size: 13px;
                margin-top: 15px;
                margin-left: 15px;
                margin-right: 15px;
                width: 100px;
                font-weight: bold;
                height: 55px;
                text-overflow: ellipsis;
                text-align: center;
                overflow: hidden;
                white-space: normal;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
        }
    }
}

@media only screen and (min-width: $breakpoint-desktop-min-width) {
    .three_sixty_thumbnails_container {
        flex-direction: column;
        margin-right: 85px;

        .t_arrow_left,
        .t_arrow_right {
            transform: rotate(0deg);
            margin-bottom: auto;
            margin-left: auto;
            margin-right: auto;
        }

        .t_arrow_left {
            margin-bottom: 15px;
        }
    
        .t_arrow_right {
            margin-top: 15px;
        }

        .three_sixty_thumbnails {
            flex-direction: column;
            max-height: 600px;
            height: 100%;

            .thumbnail_slot {
                .t_slot_image_container {
                    height: 120px;
                    width: 120px;
                    margin-left: auto;
                    margin-right: auto;

                    .t_slot_image {
                        max-height: 100px;
                        max-width: 100px;
                    }
                }

                .t_slot_label {
                    font-size: 13px;
                    width: 120px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}

@media only screen and (min-width: $breakpoint-mobile-min-width) and (max-width: 1199px){
    .three_sixty_thumbnails_container .three_sixty_thumbnails .thumbnail_slot {
        .t_slot_label {
            font-size: 11px;
        }
    }
}
