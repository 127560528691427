.scs_fallback_container {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .fallback_img {
        width: 100%;
    }
}
